(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function feature_1(){

            fns.feature_1 = feature_1;
        })();

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_name");
