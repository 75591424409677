$.lui("comp",".comp_menu",function (selector) {
    let menu = selector.find("[data-lib-tabs-nav]");
    selector.on("click","[data-lib-tabs-item=\"nav\"]",function () {
        let el = $(this);
        menu.animate({
            scrollLeft: el.closest("li")[0].offsetLeft - 40
        },300);
    })

    let filter = selector.find(['data-filter'])
    selector.on('change', '.filtr-select', function (){
        let elm = $(this);
        selector.find('[name="lastselect"]').val(elm.attr('name'));
    })

    filter.on('submit', function () {
        let elm = $(this);
        elm.next('.elm_item_content').anchor_anim(500)
    })
});
