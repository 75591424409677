(function($) {
    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_switch", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        selector.lib_flickity();
    });

    $.lui("lib_unveilhooks", "[data-bg]", function() {
        $.importScript(cdnjs.unveilhooks,null);
    });

    $.lui("lib_autosize", "[data-lib-autosize]", function(selector) {
        $.importScript(cdnjs.autosize,function () {
            autosize($(selector));
        });
    });

    $.lui("lib_gallery", "[data-lib-lightgallery]", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".elm_gallery_item").length) {
                $.importScript(cdnjs.lightgallery, function(){
                    $(selector).each(function(){
                        let gallery = $(this).lightGallery({
                            thumbnail: true,
                            selector: ".elm_gallery_item",
                            exThumbImage: 'data-exthumbimage',
                            fullScreen: false,
                            zoom: true,
                            actualSize: false,
                            hash: false,
                            download: true,
                            autoplay: false,
                            autoplayControls: false,
                        });
                        gallery.on('onAfterOpen.lg',function(event){
                            $('#lg-download').attr('data-no-swup', '');
                        });
                    });
                });
            }
        });
    });

    $.lui("lib_datepicker", "[data-lib-datepicker]", function(selector) {
        let lang_url,
            minDate = new Date(),
            maxDate = new Date();

        maxDate.setFullYear(new Date().getFullYear() + 1);

        if (lang === "ru") {
            lang_url = cdnjs.datepicker_lang;
        } else {
            lang_url = cdnjs.datepicker_lang.replace(".cs.", `.${lang}.`);
        }

        $.importScript(cdnjs.datepicker, function(){
            $.importScript(lang_url, function () {
                selector.find('input:not([type="hidden"])').datepicker({
                    language: lang,
                    position: "bottom center",
                    minDate: minDate,
                    maxDate: maxDate,
                    startDate: new Date(),
                    autoClose: true,
                    altField: selector.find('input[type="hidden"]'),
                    altFieldDateFormat: 'yyyy-mm-dd',
                    onShow: function () {
                        doc.find(".datepickers-container").addClass("state--active");
                    },
                    onHide: function () {
                        doc.find(".datepickers-container").removeClass("state--active");
                    },
                    onSelect: function () {
                        selector.find('input:not([type="hidden"])').trigger('change');
                    }
                }).data('datepicker');
            });
        });
    });

    if (document.documentElement.classList.toString().match(/(android|ios)/) === null) {
        $.lui("lib_scrollbars", "[data-lib-scrollbars]", function (selector) {
            $.importScript(cdnjs.overlayscrollbars, function () {
                selector.each(function () {
                    OverlayScrollbars($(this)[0], {
                        overflowBehavior: {
                            x: "hidden",
                            y: "scroll"
                        },
                        scrollbars: {
                            autoHide: "move",
                            autoHideDelay: 800
                        }
                    });
                });
            });
        });
    }

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });

        doc.on('click', 'button[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link.');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });

        doc.on('change', '[data-select-ajax]', function (e) {
            e.preventDefault();
            let formData = new FormData($(this)[0]);

            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link.');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: formData,
                processData: false,
                contentType: false,
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('change', '[data-date]', function (e) {
            e.preventDefault();
            let formData = new FormData($(this.closest('form'))[0]);
            let url = $(this).attr('data-date');

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: formData,
                processData: false,
                contentType: false,
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    setTimeout(function(){
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    },2500);
})(jQuery);
