function fn_lib_dialog() {
    const dialog = $("[data-lib-dialog]");

    function fn_lib_dialog_show(data, callback) {
        doc.find(".lib--dialog .part_dialog").remove();

        if (!doc.find(".lib--dialog").length) {
            body.append('<div class="lib--dialog">');
        }

        doc.find(".lib--dialog").append(data).fadeIn(300);
        doc.find(".lib--dialog .part_dialog").focus();

        if (doc.find(".lib--dialog > .part_dialog").outerHeight(true) > win.height()) {
            let offset = window.innerWidth - document.body.clientWidth;
            body.css("padding-right", offset).addClass("body--no-scroll");
            $("#layout_header").css("right", offset);
        }

        if (callback) {
            callback();
        }

        doc.find(".lib--dialog").on("click", function(e) {
            if ($(e.target).hasClass("lib--dialog")) {
                fn_lib_dialog_hide();
            }
        });
    }

    function fn_lib_dialog_hide(callback) {
        body.removeClass("overflow--no-scroll");
        dialog.removeClass("state--active");
        doc.find(".lib--dialog").fadeOut(300);

        setTimeout(function () {
            body.removeClass("body--no-scroll").css("padding-right","");
            $("#layout_header").css("right","");

            doc.find(".lib--dialog").remove();
            if (callback) {
                callback();
            }
        }, 500);
    }

    function fn_lib_dialog_init(callback) {
        doc.on('click', '[data-lib-dialog]', function (e) {
            e.preventDefault();
            let el = $(this);

            el.addClass("state--active");

            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: el.data('lib-dialog')
            }).done(function(data) {
                fn_lib_dialog_show(data.dialog, callback);
                fn_ajaxHandler(data);
            });
        });

        if (body.filter("[data-lib-dialog-open]").length) {
            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: body.data('lib-dialog-open')
            }).done(function(data) {
                bodyLoaded(function(){
                    fn_lib_dialog_show(data.dialog, callback);
                    fn_ajaxHandler(data);
                });
            });
        }

        doc.on("click", "[data-lib-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}

const lui_dialog = fn_lib_dialog();

const lui_dialog_callback = function() {
    Object.keys(window.lui.lib).forEach(function(key) {
        let dialog_selector = doc.find(".lib--dialog");
        let s = window.lui.lib[key].selector;

        if (dialog_selector.find(s).length) {
            window.lui.lib[key](key,$(".lib--dialog").find(s))
        }
    });

    Object.keys(window.lui.part).forEach(function(key) {
        if ($(`.lib--dialog ${key}`).length) {
            window.lui.part[key]("part",`.lib--dialog ${key}`)
        }
    });
};